'use client'
import useMeta from '@/store/meta'
import SCP_IconButtonWithText from '../button/IconButtonWithText'

export default function CCP_PackageHighlightButtons({ isLoading }) {
  const { taxonomies } = useMeta()
  let highlightedCategories = taxonomies?.package_category?.terms?.filter(
    (cat) => cat.is_highlight
  )
  if (isLoading) {
    highlightedCategories = [0, 0, 0]
  }
  return (
    <div className="tw-flex tw-flex-wrap tw-w-fit tw-mt-6 max-md:tw-justify-center">
      {highlightedCategories?.map((cat, i) => (
        <div key={`package-category-${i}`}>
          <SCP_IconButtonWithText
            link={`/package?selectedCategory=${cat._slug}`}
            icon={cat.icon_font}
            label={cat.title}
            isLoading={isLoading}
          />
        </div>
      ))}
    </div>
  )
}
