import { Link } from '@/navigation'
import { useTranslations } from 'next-intl'
import Image from 'next/image'

const ErrorState = () => {
  const __t = useTranslations()
  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-[40vh]">
      <Image
        alt="Error"
        src="https://bhq-cms.sgp1.cdn.digitaloceanspaces.com/uploads/2024/07/no-results.svg"
        width={68}
        height={68}
        className="tw-animate-wiggle"
      />
      <h5 className="tw-text-bgh-blue tw-mt-8 tw-mb-2 max-sm:tw-text-center">
        {__t('error-occured')}
      </h5>
      <div className="tw-text-primary tw-flex tw-flex-wrap max-sm:tw-justify-center max-sm:tw-text-center">
        <span>{__t('if-you-are-having-trouble')}</span>
        <Link
          prefetch={false}
          href="/contact"
          className="tw-inline-flex tw-items-center tw-space-x-2 tw-mx-2"
        >
          <i className="fas fa-comment-alt-smile tw-text-bgh-red" />
          <p className="tw-font-bold tw-text-bgh-red">{__t('contact-us')}</p>
        </Link>
        <span>{__t('instead')}</span>
      </div>
    </div>
  )
}

export default ErrorState
