import { Link } from '@/navigation'
import SCP_IconButton from '../button/IconButton'

export default function SCP_IconButtonCenterGroup({
  centerGroup,
  isLoading,
  addedClass = '',
}) {
  return (
    <Link
      prefetch={false}
      href={`/center-clinic/${centerGroup?._slug}`}
      className={`${centerGroup?._slug ? '' : 'tw-pointer-events-none'} ${addedClass}`}
    >
      <SCP_IconButton
        isLoading={isLoading}
        iconSrc={centerGroup?.icon?.url}
        title={centerGroup?.short_title || centerGroup?.title}
      />
    </Link>
  )
}
